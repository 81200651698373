@font-face {
    font-family: 'MontserratRegular';
    src: local('MontserratRegular'), url(./fonts/MontserratRegular.ttf) format('woff');
}

@font-face {
    font-family: 'MontserratLight';
    src: local('MontserratLight'), url(./fonts/MontserratLight.ttf) format('woff');
}


/*
height: 100vh;
*/

body {
    margin: 0;
    padding: 0;
    
    background-color: #EDEDED;
}


.col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex:1;
}

.col-stretch{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.row{
    display: flex;
    flex-direction: row;
    align-content: stretch;
}

input[type=file] { 
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
}

/*
    editando aparencias do "html5-camera"
*/
#container-circles{
    margin-bottom: 8px;
}

#inner-circle {
    background-color: rgb(255, 250, 253, .9) !important;

    top: 31.8px !important;
    left: 32.3px !important;
    width: 25px !important;
    height: 25px !important;
}

#outer-circle {
    background-color: rgb(236, 195, 224, .8) !important;
    
    right: 0px !important;
    left: -28px !important;
    top: 40px !important;
    width: 45px !important;
    height: 45px !important;
}

video{
    max-height: 60vh !important;
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: auto;
}


@media (min-width: 375px) 
{
    #container-circles{
        margin-bottom: 24px;
    }
    
    #inner-circle {
        left: 50% !important;
        top: 38px !important;
        height: 44px !important;
        width: 44px !important;
    }
    
    #outer-circle {
        width: 75px !important;
        height: 75px !important;
        top: 20px !important;
        right: 0px !important;
        left: -38px !important;
    }
}
